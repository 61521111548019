import React from 'react';
import tw from 'twin.macro';
import { Inner, Section, SectionTitle } from 'components/index';
import RibbonCard from 'components/RibbonCard';
import { useFilterContext } from 'contexts/filter';

const AreasOfInterest = () => {
  const { areasOfInterest, interests } = useFilterContext();
  return (
    <Section>
      <Inner>
        <SectionTitle>Interested in</SectionTitle>
        {(areasOfInterest.length > 0 ? areasOfInterest : interests).map((area, i) => (
          <div tw="my-8" key={`area-${i}`}>
            <h2 tw="text-green mb-4">{area.label}</h2>
            <ul tw="grid gap-4 grid-cols-2 md:(grid-cols-5)">
              {area?.urls.map(urls => (
                <RibbonCard key={urls.href} {...urls} />
              ))}
            </ul>
          </div>
        ))}
      </Inner>
    </Section>
  );
};

export default AreasOfInterest;
