import React from 'react';
import tw from 'twin.macro';
import { useFilterContext } from 'contexts/filter';
import SelectButton from 'components/SelectButton';

const AreasOfInterestFilter = ({ showAll, withIcons, ...rest }) => {
  const { interests, areasOfInterest, handleFilterAreas } = useFilterContext();
  return (
    <ul css={tw`flex flex-wrap justify-center max-w-4xl mx-auto gap-5`} {...rest}>
      {interests.map(interest => (
        <li key={interest.label} css={tw`mb-2`}>
          <SelectButton
            label={interest.label}
            isActive={areasOfInterest.includes(interest)}
            css={tw`h-full`}
            onClick={() => handleFilterAreas(interest)}
          />
        </li>
      ))}
    </ul>
  );
};

export default AreasOfInterestFilter;
