import React from 'react';
import tw, { css } from 'twin.macro';
import { A } from 'components/index';

const cardStyles = css`
  ${tw`bg-blue relative block`};
  box-shadow: 0 20px 20px -20px transparent;
  transform: perspective(1000px) rotateX(0);
  transform-style: preserve-3d;
  transition: all 200ms cubic-bezier(0.5, 1, 0.5, 1);

  :hover {
    transform: perspective(1000px) rotateX(5deg);
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.6);
  }

  img {
    ${tw`w-full h-full object-cover`};
  }
`;

const overlayStyles = css`
  position: absolute;
  height: 100%;
  right: -1px;
  left: -1px;
  bottom: 0;
  opacity: 0.9;
  background: linear-gradient(180deg, rgba(32, 48, 117, 0) 16%, #203075 70%);
`;

const RibbonCard = ({ href, label, urlImage }) => (
  <li css={cardStyles}>
    <A href={href}>
      <div tw="absolute p-5 pr-8 z-10 flex items-end h-full w-full">
        <h4 tw="font-semibold">{label}</h4>
      </div>
      <img src={urlImage.url} alt={label} />
      <div css={overlayStyles} />
    </A>
  </li>
);

export default RibbonCard;
