import React, { useEffect, useRef, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { ArrowIcon } from 'components/Icons';
import { A, Inner, Section, SectionTitle, sectionStyles } from 'components';
import { useFilterContext } from 'contexts/filter';
import { useOnScreen } from 'utils/hooks';
// import { GatsbyImage } from 'gatsby-plugin-image';
import countriesJSON from 'consts/countries.json';

const cardListStyles = css`
  transition: transform 300ms ease 0s;
  ${tw`overflow-x-scroll lg:(overflow-x-visible) opacity-0 w-full flex flex-nowrap mx-auto items-end pb-10 2xl:max-w-8xl text-white h-96`}
`;

const overlayStyles = css`
  position: absolute;
  height: 100%;
  right: -1px;
  left: -1px;
  bottom: 0;
  opacity: 0.9;
  background: linear-gradient(180deg, rgba(32, 48, 117, 0) 16%, #203075 70%);
`;

const cardStyles = css`
  ${tw`my-0 mx-5 h-full block relative`}
  border-radius: 2px;
  min-width: 28%;
  box-shadow: 0 20px 20px -20px transparent;
  transform: perspective(1000px) rotateX(0);
  transform-style: preserve-3d;
  transition: all 200ms cubic-bezier(0.5, 1, 0.5, 1);

  @media screen and (max-width: 768px) {
    min-width: 75%;
    margin: 0 10px;
  }

  :hover {
    transform: perspective(1000px) rotateX(5deg);
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.6);
  }
  :nth-of-type(odd) {
    height: 82%;
  }
  a {
    ${tw`block overflow-hidden h-full`}
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const seeAllStyles = css`
  ${tw`flex flex-row items-center cursor-pointer mt-5 sm:(mt-0)`}
  svg {
    ${tw`transform origin-left duration-500 w-5 h-5`}
  }
  :hover svg {
    ${tw`translate-x-1 -translate-y-1`}
  }
`;

const SliderNavButton = styled.button(({ next, show }) => [
  tw`hidden lg:(block absolute top-1/2 z-10 bg-white text-blue-dark h-10 w-10 rounded-full border-0 hover:bg-yellow)`,
  css`
    transition: all 0.4s cubic-bezier(0.5, 0, 0.5, 1);
    svg {
      width: 20px;
      transform: rotate(${next ? 0 : 180}deg);
    }
  `,
  css`
    transform: scale(${show ? 1 : 0});
  `,
  next
    ? css`
        right: 80px;
      `
    : css`
        left: 80px;
      `,
]);

const RelatedRibbon = ({ array, heading, ...rest }) => {
  const cardRef = useRef();
  const { selectedCountry } = useFilterContext();
  const [ref, visible] = useOnScreen();
  const [x, setX] = useState(0);
  const [slides, setSlides] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [relatedItems, setRelatedItems] = useState();

  useEffect(() => {
    const selectedCountryRegion = countriesJSON.find(c => c.name === selectedCountry);
    const relatedToCountry = [];
    const relatedToContinent = [];
    const rest = [];

    array?.map(t => {
      const homeCountry = t.expert?.homeCountry.isoStandardName;
      if (homeCountry === selectedCountry) relatedToCountry.push(t);
      else if (t.expert?.homeCountry.continent.label === selectedCountryRegion?.region)
        relatedToContinent.push(t);
      else rest.push(t);
    });

    const all = relatedToCountry.concat(relatedToContinent).concat(rest);
    setRelatedItems(all);
  }, [visible, selectedCountry]);

  useEffect(() => {
    if (cardRef.current && relatedItems) {
      const cardW = cardRef.current?.getBoundingClientRect().width;
      const cardsCount = relatedItems.length;

      const px = cardW * 3;
      const setsOf3 = Math.floor(cardsCount / 3);
      const hasRemainders = cardsCount / 3 - setsOf3 !== 0;
      const remainderPx = hasRemainders && Math.round(cardsCount / 3 - setsOf3) === 0 ? 307 : 700;

      const arr = [];
      for (let i = 0; i < setsOf3; i++) arr.push(px);
      if (hasRemainders) arr.push(remainderPx);

      setSlides(arr);
    }
  }, [cardRef, relatedItems]);

  const onSliderNav = direction => {
    let px;
    if (direction === 'next') {
      const next = currentPage + 1;
      px = x - slides[next];
      setCurrentPage(next);
    } else {
      const prev = currentPage - 1;
      px = x + slides[currentPage];
      setCurrentPage(prev);
    }
    setX(px);
  };

  // console.log('relatedItems', relatedItems);

  // return null;

  return (
    <Section css={sectionStyles} {...rest}>
      <Inner
        tw="text-white flex flex-col justify-between px-5 mb-12 sm:(flex-row) lg:(max-w-7xl px-20 mb-24) xl:(px-0)"
        ref={ref}
      >
        <SectionTitle>{heading}</SectionTitle>
        <h3 tw="font-semibold text-blue-dark">You may also be interested in</h3>
        <A href="https://blog.digitalhubdenmark.dk/" css={seeAllStyles} withArrow>
          <span tw="mr-1">Our Blog</span>
        </A>
      </Inner>
      <SliderNavButton
        onClick={() => onSliderNav()}
        show={currentPage !== 0}
        disabled={currentPage === 0}
      >
        <ArrowIcon />
      </SliderNavButton>
      <SliderNavButton
        onClick={() => onSliderNav('next')}
        show={currentPage < slides?.length - 1}
        disabled={currentPage > slides?.length - 1}
        next
      >
        <ArrowIcon />
      </SliderNavButton>
      <ul
        css={[
          cardListStyles,
          relatedItems &&
            css`
              ${tw`opacity-100`}
            `,
        ]}
        style={{ transform: `translateX(${x}px)` }}
      >
        {relatedItems?.map(card => {
          const { expert, title, ribbonCaption, slug } = card;
          const image = expert?.featuredMedia.gatsbyImageData || card.featuredMedia.gatsbyImageData;
          const heading = card.expert ? title : card.company.name;
          if (!image) return null;
          return (
            <li css={cardStyles} key={card.slug} ref={cardRef}>
              <A href={`https://blog.digitalhubdenmark.dk/${slug}`}>
                <div tw="absolute bottom-0 p-5 pr-8 z-10 flex flex-col">
                  <h4 tw="font-semibold">{heading}</h4>
                  <p tw="text-base mb-2 mt-1">{card.expert ? ribbonCaption : title}</p>
                </div>
                {/* <GatsbyImage image={image} alt={heading} /> */}
                <img src={image.images.fallback.src} alt={title} />
                <div css={overlayStyles} />
              </A>
            </li>
          );
        })}
      </ul>
    </Section>
  );
};

export default RelatedRibbon;
