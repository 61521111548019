import React, { useEffect } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import tw, { css } from 'twin.macro';
import Layout from 'components/Layout';
import { Section, Inner, PageTitle } from 'components';
import AreasOfInterestFilter from 'components/AreasOfInterestFilter';
// import SelectCountry from 'components/SelectCountry';
import Filter from 'components/Filter';
import DenmarkInNumbers from 'modules/DenmarkInNumbers';
import Jobs from 'modules/Jobs';
import AreasOfInterests from 'modules/AreasOfInterests';
import NewsletterSlim from 'modules/NewsletterSlim';
import RelatedRibbon from 'modules/RelatedRibbon';
import TiltButton from 'components/TiltButton';
import { useFilterContext } from 'contexts/filter';

const denmarkInNumbersStyles = css`
  > div > svg {
    display: none;
  }
  ${tw`sm:(mt-0 pt-0)`}
`;

const resultsFilterStyles = css`
  ${tw`flex justify-start gap-0 m-0`}
  button {
    ${tw`text-xs m-0 py-2 px-3 mr-2`}
  }
  li {
    ${tw`mb-3`}
  }
`;

const Label = tw.h3`my-3`;

const resultsFilterContainerStyles = css`
  ${tw`grid md:grid-cols-2 border border-solid border-white-50 mt-4 sm:mt-0`}
  > div:nth-of-type(2) {
    ${tw`border-0 border-l border-r-0 border-solid border-white-50`}
  }
  svg {
    width: 16px;
    right: 10px;
  }
`;

const resultsQuery = graphql`
  {
    datoCmsPage(title: { eq: "Talented in Denmark" }) {
      blocks {
        ... on DatoCmsDenmarkInNumber {
          model {
            apiKey
          }
          heading
          bodyText
          facts {
            number
            label
            icon {
              url
            }
          }
        }
      }
    }
    allDatoCmsCompanyCase {
      edges {
        node {
          title
          slug
          featuredMedia {
            gatsbyImageData(width: 410)
          }
          company {
            name
          }
        }
      }
    }
  }
`;

const ResultsPage = () => {
  const { talents, roleFilters, areasOfInterest } = useFilterContext();
  const {
    datoCmsPage: { blocks },
    allDatoCmsCompanyCase: { edges },
  } = useStaticQuery(resultsQuery);
  const { facts } = blocks.find(block => block.model?.apiKey === 'denmark_in_number');
  const companyCases = edges.map(c => c.node);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'techTalentFunnelCompleted',
      });
    }
  }, []);

  useEffect(() => {
    const resultsPath = '/opportunities/results';
    if (roleFilters.length || areasOfInterest.length) {
      const params = new URLSearchParams({});

      roleFilters.forEach(filter => filter && params.append('roles', filter.label));
      areasOfInterest.forEach(filter => filter && params.append('interests', filter.label));

      const resultsUrl = `${resultsPath}${params ? `?${params.toString()}` : ''}`;

      navigate(resultsUrl, {
        replace: true,
      });
    } else {
      navigate(resultsPath, {
        replace: true,
      });
    }
  }, [roleFilters, areasOfInterest]);

  return (
    <Layout>
      <Section>
        <Inner>
          <PageTitle tw="mt-20 text-center mb-6">Your Results</PageTitle>
          <div css={resultsFilterContainerStyles}>
            <div tw="px-4">
              <Label>Roles</Label>
              <Filter inFunnel css={resultsFilterStyles} />
            </div>
            <div tw="px-4 pb-2">
              <Label>Interested in</Label>
              <AreasOfInterestFilter css={resultsFilterStyles} />
            </div>
            {/* <div tw="col-span-1 px-4 pb-6 lg:pb-0">
              <Label>Country</Label>
              <SelectCountry tw="text-xs font-normal w-full py-2" />
            </div> */}
          </div>
        </Inner>
      </Section>
      <DenmarkInNumbers facts={facts} css={denmarkInNumbersStyles} tw="my-0 py-0" />
      <Jobs />
      {/* <TheHubJobs />
      <RoboticsJobs /> */}
      <AreasOfInterests />
      <RelatedRibbon array={talents} heading="Interviews" tw="lg:pb-0" />
      <RelatedRibbon array={companyCases} heading="Startups in Denmark" />
      <NewsletterSlim />
      <Section>
        <Inner tw="text-center">
          <TiltButton label="Back to the Talent Portraits" to="/#talents" reverse />
        </Inner>
      </Section>
    </Layout>
  );
};

export default ResultsPage;
