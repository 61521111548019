import React, { useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import axios from 'axios';
import { Section, Inner, SectionHeader, SectionTitle, A } from 'components';
import TiltButton from 'components/TiltButton';
import { useFilterContext } from 'contexts/filter';
import { checkIfDanish } from 'utils';

const thehubio = 'https://thehub.io/';

const JobsGrid = tw.ul`grid gap-2 md:(grid-cols-3 gap-8) min-h-84`;

const cardStyles = css`
  ${tw`bg-white text-black p-3 md:p-5 flex transition-shadow duration-200 ease-in-out hover:opacity-90`}
`;

const logoStyles = css`
  max-width: 40px;
  ${tw`mr-4`}
`;

export const JobCard = ({ companyName, url, jobLabel, logoUrl }) => (
  <li>
    <A href={url} css={cardStyles}>
      <div tw="col-span-2 flex flex-col justify-between md:h-28">
        <h4 tw="font-semibold text-lg">{jobLabel}</h4>
        <div tw="flex items-center">
          <img src={logoUrl} css={logoStyles} alt={`${companyName} logo`} />
          <span tw="font-semibold text-xs">{companyName}</span>
        </div>
      </div>
    </A>
  </li>
);

const moreJobsStyles = css`
  @media only screen and (max-width: 768px) {
    ${tw`w-full`}
  }
  span {
    ${tw`text-xs`}
  }
  a {
    ${tw`px-8`}
  }
`;

const MoreJobsButton = ({ label, to }) => (
  <li tw="flex items-center justify-center">
    <TiltButton
      label={label}
      to={to}
      tw="mt-6 md:mt-0"
      css={moreJobsStyles}
      arrow
      external
      arrowDirection={css`
        stroke-width: 1.5;
        transform: rotate(-45deg);
      `}
    />
  </li>
);

const Jobs = () => {
  const { roleFilters, setJobsCount } = useFilterContext();
  const [roboticJobs, setRoboticsJobs] = useState();
  const [hubJobs, setHubJobs] = useState();
  const [allJobs, setAllJobs] = useState();
  const [queryString, setQueryString] = useState();
  const [filteredRoboticsJobs, setFilteredRoboticsJobs] = useState();

  const fetchRoboticsJobs = async () => {
    const url = 'https://hr-skyen.dk:3000/odenserobotics/jobposting';
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: process.env.GATSBY_ROBOTICS_JOBS_API,
        },
      });
      const jobsArr = [];
      data.results.map(j => {
        if (jobsArr.length > 11) return null;
        if (checkIfDanish(j.title)) return null;
        jobsArr.push({
          id: j.jobpostid,
          logoUrl: j.company_logo,
          companyName: j.company_name,
          jobLabel: j.title,
          categories: j.job_categories,
          url: j.applyurl,
        });
      });
      setRoboticsJobs(jobsArr);
    } catch (e) {
      console.log('Error fetching jobs', e);
    }
  };

  const theHubRolesString = () => {
    const arr = [];
    roleFilters.map(a => a.theHubRoles.map(role => arr.push(role.queryId)));
    const uniqueRolesIds = arr.filter((item, index) => arr.indexOf(item) === index);
    let queryString = '';
    uniqueRolesIds.forEach(id => (queryString += `roles=${id}&`));

    const string = queryString;
    setQueryString(queryString);
    return string;
  };

  const fetchTheHub = async () => {
    const roles = theHubRolesString();

    let baseURL;

    if (typeof window !== 'undefined') baseURL = window.location.origin;
    // ! Remember "netlify dev" to run location environment & serverless functions

    const url = `${baseURL}/proxy?${roles}`;

    try {
      const { data } = await axios.get(url);

      const jobsArr = [];

      data.docs?.map(j => {
        if (!checkIfDanish(j.title)) {
          jobsArr.push({
            companyName: j.company.name,
            logoUrl: `https://thehub-io.imgix.net${j.company.logoImage.path}?fit=crop&w=40&h=40&q=75`,
            jobLabel: j.title,
            url: j.absoluteJobUrl,
            key: j.id,
          });
        }
      });

      const jobsCount = data.total;

      setHubJobs(jobsArr);
      setJobsCount(jobsCount);
    } catch (e) {
      console.log('Error fetching jobs', e);
    }
  };

  useEffect(() => {
    fetchTheHub();
  }, [roleFilters]);

  useEffect(() => {
    fetchRoboticsJobs();
  }, []);

  useEffect(() => {
    if (filteredRoboticsJobs || hubJobs) {
      let arr = [];
      if (filteredRoboticsJobs) arr = arr.concat(filteredRoboticsJobs);
      if (hubJobs) arr = arr.concat(hubJobs);
      arr.reverse();
      setAllJobs(arr);
    }
  }, [filteredRoboticsJobs, hubJobs, roleFilters]);

  useEffect(() => {
    const filterLabels = roleFilters.map(l => l.label);

    if (allJobs) {
      let arr = [];
      if (filterLabels.length === 0) {
        const first11 = roboticJobs.slice(0, 11);
        setFilteredRoboticsJobs(first11);
      } else {
        // loop all jobs
        for (let i = 0; i < roboticJobs.length && arr.length < 11; i++) {
          // each job[i].categories[]
          for (let k = 0; k < roboticJobs[i].categories.length; k++) {
            // compare filters[] to category
            const isSales =
              (filterLabels.includes('Marketing') ||
                filterLabels.includes('Sales') ||
                filterLabels.includes('Commercial')) &&
              roboticJobs[i].categories.includes('Sales & Marketing');

            const isEngineering =
              (filterLabels.includes('Engineer') ||
                filterLabels.includes('Developer') ||
                filterLabels.includes('Data Scientist')) &&
              (roboticJobs[i].categories.includes('IT') ||
                roboticJobs[i].categories.includes('Technical'));

            if (isSales || isEngineering) {
              arr.push(roboticJobs[i]);
              break;
            }
          }
        }
        if (arr.length === 0) {
          const first11 = roboticJobs.slice(0, 11);
          arr = first11;
        }
        setFilteredRoboticsJobs(arr);
      }
    }
  }, [roboticJobs, roleFilters]);

  return (
    <Section>
      <Inner>
        <SectionHeader>
          <SectionTitle>Latest Jobs Matching Your Profile</SectionTitle>
          <div tw="sm:mt-4">
            Powered by{' '}
            <A href={thehubio} tw="underline inline-block">
              TheHub
            </A>{' '}
            &{' '}
            <A href="https://robotic-careers.com/" tw="underline inline-block">
              Robotics Careers
            </A>
          </div>
        </SectionHeader>
        <JobsGrid>
          {allJobs ? (
            <>
              {allJobs.map(({ companyName, logoUrl, jobLabel, url, id }) => (
                <JobCard
                  companyName={companyName}
                  logoUrl={logoUrl}
                  jobLabel={jobLabel}
                  url={url}
                  key={id}
                />
              ))}
            </>
          ) : (
            <h1>Loading</h1>
          )}
        </JobsGrid>
        <div tw="grid sm:grid-cols-3 sm:mt-10">
          <MoreJobsButton
            label="See more jobs on thehub.io"
            to={`${thehubio}jobs?${queryString}&countryCode=DK`}
          />
          <MoreJobsButton
            label="See jobs within robotics"
            to="https://robotic-careers.com/job-bank/"
          />
          <MoreJobsButton
            label="See jobs in Greater Copenhagen"
            to="https://careers.greatercph.com/"
          />
        </div>
      </Inner>
    </Section>
  );
};

export default Jobs;
